<template>
  <div class="d-flex flex-column">
    <span>{{ $t("lbl_assign_processor.lbl_status") }}</span>
    <v-select
      v-model="current_status"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="dropdownOptions"
      label="statusName"
      :reduce="(opt) => opt.value"
      :clearable="false"
      class="option-width inline-block mx-50"
      @input="handleChangeStatus"
    >
      <template #selected-option="props">
        <span>{{ getLabelSelected(props) }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import i18n from "@/libs/i18n";
import vSelect from "vue-select";

export default {
  name: "StatusDropdown",
  props: {
    currentStatus: {
      type: Number,
      default: 1,
    },
    dropdownOptions: {
      type: Array,
      default: () => [
        {
          value: null,
          statusName: i18n.t("lbl_on_sale"),
        },
        {
          value: 50,
          statusName: i18n.t("lbl_off_sale"),
        },
      ],
    },
  },
  components: { vSelect },
  data() {
    return {
      current_status: this.currentStatus,
    };
  },
  methods: {
    handleChangeStatus(value) {
      this.$emit("setStatus", value);
    },
    getLabelSelected(data) {
      return this.dropdownOptions?.find((item) => item?.value === data?.value)
        ?.statusName;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.option-width {
  width: 250px;
}
</style>
